import { Typography } from "antd"
import { useEffect, useState } from "react"
import { IArticle } from "../../../../types/IArticle"
import { ArticleForm } from "../../../article/components/ArticleForm/ArticleForm"
import NDFSelect from "../../../shared/form/components/select/NDFSelect"
import { useDevisContext } from "../../context/devis.context"
import { IRow } from "../../models/IDevis"

interface Props {
  record: IRow
  onSelect?: (article: IArticle) => void
}

const ArticleRow: React.FC<Props> = (props) => {
  const [loading, setLoading] = useState(false)
  const [search, setSearch] = useState("")
  const [showModal, setShowModal] = useState(false)

  const { articles, searchArticles } = useDevisContext()

  useEffect(() => {
    setLoading(true)

    const timeout = setTimeout(() => {
      searchArticlesByName(search)
    }, 500)

    return () => {
      clearTimeout(timeout)
      setLoading(false)
    }
  }, [search])

  useEffect(() => {
    setSearch(props.record.article?.artName ?? "")
  }, [props.record.article?.artName])

  const searchArticlesByName = (search: string) => {
    if (search.length && search.length < 3) {
      return
    }
    searchArticles(search).then(() => {
      setSearch(search)
      setLoading(false)
    })
  }

  return (
    <>
      <NDFSelect
        className='article-select'
        onSearch={setSearch}
        search={search}
        onSelect={() => {}}
        options={articles}
        value={undefined}
        onNew={() => setShowModal(true)}
        isLoading={loading}
      />
      <Typography.Text>{props.record?.article?.artDesc}</Typography.Text>
      <ArticleForm
        id={undefined}
        isOpen={showModal}
        onClose={() => setShowModal(false)}
        onSubmit={(article) => {
          setShowModal(false)
          if (article) {
            searchArticlesByName(article.artName)
            props.onSelect && props.onSelect(article)
          }
        }}
      />
    </>
  )
}

export default ArticleRow
