import { ClearOutlined } from "@ant-design/icons"
import { Button, Divider, Select, Spin } from "antd"
import { ComponentProps } from "react"
import { PlusSmall } from "react-flaticons"
import "./select.scss"

export interface NDFSelectOptionType {
  value: number
  label: string
}

interface Props extends Omit<ComponentProps<typeof Select>, "onSelect" | "placeholder"> {
  search: string
  value: number | undefined
  onSearch: (value: string) => void
  onSelect: (value: number) => void
  onNew?: () => void
  isLoading?: boolean
  options: NDFSelectOptionType[]
  placeholder?: { notFound: string; search: string }
}

const NDFSelect = (props: Props) => {
  const {
    search,
    onSearch,
    onSelect,
    options,
    value,
    onNew,
    isLoading,
    placeholder = { notFound: "Aucun résultat", search: "Recherchez" },
    className,
  } = props

  const notFound = () => {
    if (isLoading && search.length >= 3) {
      return <Spin />
    }
    return search.length >= 3 ? placeholder.notFound : placeholder.search
  }

  return (
    <Select<number>
      className={className}
      placeholder='Rechercher'
      notFoundContent={notFound()}
      showSearch
      filterOption={(inputValue, option) => {
        if (option?.label) {
          return option.label.toString().toLowerCase().includes(inputValue.toLowerCase())
        }
        return false
      }}
      searchValue={search}
      value={value}
      options={options}
      onSearch={onSearch}
      allowClear={{ clearIcon: <ClearOutlined /> }}
      onSelect={onSelect}
      dropdownRender={(menu) => (
        <>
          {menu}

          {onNew && (
            <>
              <Divider className='select-divider' />
              <Button
                className='select-add-new-button'
                type='text'
                icon={<PlusSmall />}
                onClick={onNew}
              >
                Créer un nouveau
              </Button>
            </>
          )}
        </>
      )}
    />
  )
}

export default NDFSelect
