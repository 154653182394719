import { IArticle } from "../../../types/IArticle"
import { ISaleUnit } from "../../../types/ISaleUnit"
import { ITaxe } from "../../../types/ITaxe"
import { EDevisStatus } from "./EDevisStatus"

export interface IDevis {
  IDDevis: number
  creatorName: string
  numPiece: string
  isModele: number
  datePiece: string
  dateValidite: null
  devisStatut: EDevisStatus
  devisTitle: string
  devisTotalHT: number
  devisTotalTaxes: null
  devisTotalTTC: null
  devisCGV: null
  IDTiers: number
  tiers_displayName: string
  tiers_formattedAddress: null
  tiers_adresse: null
  tiers_codePostal: null
  tiers_ville: null
  tiers_countryCode: null
  tiers_countryName: null
  _lignes: Ligne[][]
}

interface Ligne {
  IDDevisLigne: number
  IDArt: number
  IDTaxe: number
  IDSaleUnit: number
  numChapitre: number
  numLigne: number
}

export class DiscountType {
  static PERCENT = "P"
  static AMOUNT = "V"
}

export class Discount {
  type: string

  constructor(type: DiscountType) {
    this.type = type.toString()
  }

  getType() {
    return this.type === DiscountType.PERCENT ? "%" : "€"
  }

  static getLabel(type: string) {
    return type === DiscountType.PERCENT ? "%" : "€"
  }

  static getOptions() {
    return [
      { value: DiscountType.PERCENT, label: Discount.getLabel(DiscountType.PERCENT) },
      { value: DiscountType.AMOUNT, label: Discount.getLabel(DiscountType.AMOUNT) },
    ]
  }
}

export interface IRow {
  key: string

  title?: string

  article: IArticle

  quantity: number

  unit: ISaleUnit

  price: number
  discount: { type: Discount; value: number }

  tax: ITaxe
  sum: string
}
