import { UploadOutlined } from "@ant-design/icons"
import {
  Button,
  Col,
  DatePicker,
  Descriptions,
  DescriptionsProps,
  Divider,
  Dropdown,
  Flex,
  Form,
  Input,
  message,
  Row,
  Typography,
  Upload,
  UploadProps,
} from "antd"
import dayjs from "dayjs"
import React, { useEffect, useState } from "react"
import { CaretDown, Plus } from "react-flaticons"
import { IArticle } from "../../../../types/IArticle"
import { IFormContentProps } from "../../../../types/IFormProps"
import { ContactSearch } from "../../../shared/form/components/select/contact/ContactSearch"
import { EMPTY_ROW, useDevisContext } from "../../context/devis.context"
import { IDevisNumbering } from "../../models/IDevisNumbering"
import { DevisAPI } from "../../services/Devis.api"
import { DevisNumbering } from "../Numbering/DevisNumbering"
import { NDFTable } from "../Table/NDFTable"
import "./devisFormContent.scss"

const DATE_FORMAT = "DD-MM-YYYY"

// TODO Remove this

export const DevisFormContent: React.FC<IFormContentProps> = ({ id: devisId, onLoad }) => {
  const isEdition = !!devisId
  const form = Form.useFormInstance()
  const [contact, setContact] = useState<number>()

  const [numbering, setNumbering] = useState<IDevisNumbering>()

  useEffect(() => {
    setFormData()
  }, [devisId])

  const setFormData = () => {
    onLoad(true)

    getNumbering()

    if (!isEdition) {
      form.setFieldsValue({
        nom: "",
        prenom: "",
        denomination: "",
        IDEnt: "",
        numTVA: "",
        fonction: "",
        website: "",
        tags: [],
        notes: "",
      })
      return
    }

    // DevisAPI.getDevis(contactId).then((res) => {
    //   setIsContactACompany(res.data.companyType === EContactType.ENTREPRISE)
    //   form.setFieldsValue({
    //     ...res.data,
    //     tags: res.data.tags.map((tag: ITag) => tag.IDTag),
    //     companyType: res.data.companyType,
    //     IDEnt: res.data.enterprise?.entName,
    //   })
    //   onLoad(false)
    // })
  }

  const getNumbering = () => {
    DevisAPI.getDevisNumbering()
      .then((res) => {
        setNumbering(res.data)
      })
      .finally(() => {
        onLoad(false)
      })
  }

  return (
    <Row className='devis-form'>
      <Col xs={24} xl={12}>
        <ContactSearch onSelect={(id) => setContact(id)} value={contact} label='Nom du client' />
      </Col>

      <Divider className='first-divider' />

      {numbering && numbering.counterDV === 0 && (
        <Col xs={24} xl={12}>
          <DevisNumbering numbering={numbering} onClose={() => getNumbering()} />
        </Col>
      )}

      <Col xs={24}>
        <Row>
          <Col xs={24} xl={12}>
            <Form.Item
              label='Date du devis'
              rules={[
                {
                  required: true,
                  message: "Veuillez renseigner la date du devis",
                },
              ]}
            >
              <DatePicker defaultValue={dayjs()} format={DATE_FORMAT} />
            </Form.Item>
          </Col>
          <Col xs={24} xl={12}>
            <Form.Item
              label="Date d'expiration"
              className='expiration-date'
              rules={[
                {
                  required: true,
                  message: "Veuillez renseigner la date d'expiration du devis",
                },
              ]}
            >
              <DatePicker defaultValue={dayjs().add(1, "month")} format={DATE_FORMAT} />
            </Form.Item>
          </Col>
        </Row>
      </Col>

      <Col xs={24} xl={12}>
        <Form.Item label='Titre du devis'>
          <Input placeholder='Devis' />
        </Form.Item>
      </Col>

      <DevisTable />

      <Divider />

      <Col xs={24}>
        <Row gutter={{ xl: 20 }}>
          <Col xs={24} xl={16}>
            <Form.Item label='Informations pour le client'>
              <Input.TextArea rows={4} cols={2} />
            </Form.Item>
            <Form.Item label='Conditions générales'>
              <Input.TextArea rows={4} />
            </Form.Item>
          </Col>
          <Col xs={24} xl={8}>
            <FileUpload />
          </Col>
        </Row>
      </Col>
    </Row>
  )
}

const DevisTable = () => {
  const { lines, setLines } = useDevisContext()

  const { addLine, addTitle } = useDevisContext()

  useEffect(() => {
    setLines([EMPTY_ROW])
  }, [])

  const priceDetails: DescriptionsProps["items"] = [
    {
      label: "Sous-total",
      children: "€ 0,00",
      span: 4,
    },
    {
      label: "TOTAL (€)",
      children: "€ 0,00",
      span: 4,
    },
  ]

  return (
    <>
      <Col xs={24} className='devis-lines'>
        <NDFTable />
      </Col>
      <Col xs={24} xl={12}>
        <Dropdown
          overlayClassName='devis-row-actions'
          menu={{
            items: [
              {
                key: "1",
                label: "Ajouter une ligne",
                // TODO make this add after the current line
                onClick: () =>
                  addLine({
                    ...EMPTY_ROW,
                    article: {
                      IDArt: 0,
                      artName: `King ${lines.length}`,
                      artDesc: `Edward King ${lines.length}`,
                    } as IArticle,
                  }),
              },
              {
                key: "2",
                label: "Ajouter un titre",
                onClick: () => addTitle("Nouveau titre"),
              },
            ],
          }}
          placement='bottom'
        >
          <Button className='add-line' icon={<Plus size={15} />}>
            Ajouter une nouvelle ligne <CaretDown size={15} />
          </Button>
        </Dropdown>
      </Col>
      <Col xs={24} xl={12}>
        <Descriptions
          bordered
          column={{ xs: 1, sm: 2, md: 3, lg: 3, xl: 4, xxl: 4 }}
          items={priceDetails}
        />
      </Col>
    </>
  )
}

const FileUpload = () => {
  // TODO: Implement the upload logic
  const props: UploadProps = {
    name: "file",
    action: "https://660d2bd96ddfa2943b33731c.mockapi.io/api/upload",
    headers: {
      authorization: "authorization-text",
    },
    onChange(info) {
      if (info.file.status !== "uploading") {
        console.log(info.file, info.fileList)
      }
      if (info.file.status === "done") {
        message.success(`${info.file.name} file uploaded successfully`)
      } else if (info.file.status === "error") {
        message.error(`${info.file.name} file upload failed.`)
      }
    },
  }

  return (
    <Flex className='upload-file' justify='flex-start' align='flex-end' vertical>
      <div className='upload'>
        <Upload {...props}>
          <Button icon={<UploadOutlined />}>Joindre un fichier</Button>
        </Upload>
        <Typography.Text type='secondary'>
          Formats acceptés: PDF, DOCX, XLSX, JPG, PNG
          <br />
          Taille maximale: 5 Mo
        </Typography.Text>
      </div>
    </Flex>
  )
}
