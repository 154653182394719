import dayjs from "dayjs"
import { createContext, useContext, useEffect, useMemo, useState } from "react"
import { ArticleAPI } from "../../../services/Article.api"
import { IArticle } from "../../../types/IArticle"
import { IContact } from "../../../types/IContact"
import { ISaleUnit } from "../../../types/ISaleUnit"
import { ITaxe } from "../../../types/ITaxe"
import { NDFSelectOptionType } from "../../shared/form/components/select/NDFSelect"
import { Discount, DiscountType, IRow } from "../models/IDevis"

interface IRowWithoutKey extends Omit<IRow, "key"> {
  key?: string
}

export const EMPTY_ROW: IRowWithoutKey = {
  article: { IDArt: 0, artName: "Dummy", artDesc: "Dummy desc" } as IArticle,
  quantity: 1,
  unit: { IDSaleUnit: 0, suDisplayName: "U", suName: "Unité" },
  price: 10,
  discount: {
    type: new Discount(DiscountType.PERCENT),
    value: 0,
  },
  tax: { IDTaxe: 0, taxeName: "Dummy", taxeCoeff: 0 },
  sum: "10",
}

interface DevisContextType {
  contact: IContact
  setContact: (contact: IContact) => void

  devisNumber: string
  creationDate: string
  validityDate: string
  title: string

  lines: IRow[]
  setLines: (lines: IRowWithoutKey[], withExistingKeys?: boolean) => void
  addLine: (line?: IRowWithoutKey) => void
  addTitle: (title: string) => void

  additionalInformation: string
  termsAndConditions: string
  // TODO used type might not be correct
  uploadedFiles: File[]

  articles: NDFSelectOptionType[]
  searchArticles: (search: string) => Promise<unknown>

  //   setContact: (contact: IContact) => void
  //   setDevisNumber: (devisNumber: string) => void
  //   setCreationDate: (creationDate: string) => void
  //   setValidityDate: (validityDate: string) => void
  //   setTitle: (title: string) => void
  //   setLines: (lines: IRow[]) => void
  //   setAdditionalInformation: (additionalInformation: string) => void
  //   setTermsAndConditions: (termsAndConditions: string) => void
  //   setUploadedFiles: (uploadedFiles: File[]) => void
}

const DevisContext = createContext<DevisContextType>({
  contact: {} as IContact,
  setContact: () => {},

  devisNumber: "",
  creationDate: dayjs().toString(),
  validityDate: dayjs().add(1, "month").toString(),
  title: "",

  lines: [],
  setLines: () => {},
  addLine: () => {},
  addTitle: () => {},

  additionalInformation: "",
  termsAndConditions: "",
  uploadedFiles: [],

  articles: [],
  searchArticles: () => {
    return {} as Promise<unknown>
  },
})

export const useDevisContext = () => useContext(DevisContext)

const DevisContextProvider = ({ children }: { children: React.ReactNode }) => {
  const [contact, setContact] = useState<IContact>({} as IContact)
  const [lines, setLines] = useState<IRow[]>([])
  let rowKeyIndex = 1

  const [articles, setArticles] = useState<NDFSelectOptionType[]>([])

  useEffect(() => {
    searchArticles()
  }, [])

  const searchArticles = async (search: string = "") => {
    if (search !== "" && search.length < 3) {
      return Promise.resolve()
    }
    return ArticleAPI.searchArticles(search).then((res) => {
      if (res.ok) {
        setArticles(res.data.map((article) => ({ value: article.IDArt, label: article.artName })))
      }
    })
  }

  const getKey = () => {
    return new Date().valueOf() + rowKeyIndex++ + ""
  }

  const addLine = (line?: IRowWithoutKey) => {
    if (line) {
      setLines((current) => [...current, { ...line, key: getKey() }])
      return
    }

    setLines((current) => [...current, { ...EMPTY_ROW, key: getKey() }])
  }

  const setAllLines = (lines: IRowWithoutKey[], withExistingKeys?: boolean) => {
    setLines(
      lines.map((line) => ({
        ...line,
        key: withExistingKeys ? line.key! : getKey(),
      })),
    )
  }

  const context = useMemo<DevisContextType>(
    () => ({
      contact: contact,
      setContact: (contact: IContact) => setContact(contact),

      devisNumber: "",
      creationDate: dayjs().toString(),
      validityDate: dayjs().add(1, "month").toString(),
      title: "",

      lines: lines,
      setLines: (lines: IRowWithoutKey[], withExistingKeys?: boolean) =>
        setAllLines(lines, withExistingKeys),
      addLine,
      addTitle: (title: string) =>
        addLine({
          title,
          article: undefined as unknown as IArticle,
          quantity: 0,
          unit: undefined as unknown as ISaleUnit,
          price: 0,
          discount: {
            type: new Discount(DiscountType.PERCENT),
            value: 0,
          },
          tax: undefined as unknown as ITaxe,
          sum: "0",
        }),
      additionalInformation: "",
      termsAndConditions: "",
      uploadedFiles: [],

      articles,
      searchArticles: (search: string) => searchArticles(search),
    }),
    [articles, lines, searchArticles],
  )

  return <DevisContext.Provider value={context}>{children}</DevisContext.Provider>
}

export default DevisContextProvider
