import { HolderOutlined } from "@ant-design/icons"
import { DndContext, DragEndEvent } from "@dnd-kit/core"
import { SyntheticListenerMap } from "@dnd-kit/core/dist/hooks/utilities"
import { restrictToVerticalAxis } from "@dnd-kit/modifiers"
import {
  arrayMove,
  SortableContext,
  useSortable,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable"
import { CSS } from "@dnd-kit/utilities"
import {
  Button,
  Divider,
  Dropdown,
  Flex,
  Input,
  InputNumber,
  Select,
  Table,
  Typography,
} from "antd"
import React, { useContext, useEffect, useMemo, useState } from "react"
import { ArticleAPI } from "../../../../services/Article.api"
import { Discount, DiscountType, IRow } from "../../models/IDevis"
import ArticleRow from "./ArticleRow"

import { MenuDotsVertical, X } from "react-flaticons"
import { IArticle } from "../../../../types/IArticle"
import { useDevisContext } from "../../context/devis.context"
import "./table.scss"

type EditableTableProps = Parameters<typeof Table>[0]

type ColumnTypes = Exclude<EditableTableProps["columns"], undefined>

type ColumnType = Omit<ColumnTypes[number], "render"> & {
  editable?: boolean
  dataIndex: string
  render?: (text: unknown, record: IRow) => React.ReactNode
}

export const NDFTable: React.FC = () => {
  const [taxes, setTaxes] = useState<{ value: number; label: string }[]>([])
  const [units, setUnits] = useState<{ value: number; label: string; title: string }[]>([])

  const { lines, addLine, addTitle, setLines } = useDevisContext()

  useEffect(() => {
    console.log(lines)
  }, [lines])

  useEffect(() => {
    ArticleAPI.getTaxes().then((res) => {
      if (res.ok) {
        setTaxes(res.data.map((tax) => ({ value: tax.IDTaxe, label: tax.taxeName })))
      }
    })
    ArticleAPI.getSaleUnits().then((res) => {
      if (res.ok) {
        setUnits(
          res.data.map((unit) => ({
            value: unit.IDSaleUnit,
            label: unit.suDisplayName,
            title: unit.suName,
          })),
        )
      }
    })
  }, [])

  const onDragEnd = ({ active, over }: DragEndEvent) => {
    if (active.id !== over?.id) {
      const activeIndex = lines.findIndex((record) => record.key === active?.id)
      const overIndex = lines.findIndex((record) => record.key === over?.id)
      setLines(arrayMove(lines, activeIndex, overIndex), true)
    }
  }

  const handleDelete = (key: React.Key) => {
    const newData = lines.filter((item) => item.key !== key)
    setLines(newData, true)
  }

  const onArticleSelect = (article: IArticle) => {
    console.log(article)
  }

  const onTaxChange = (tax: any) => {
    console.log(tax)
  }

  const onUnitChange = (unit: any) => {
    console.log(unit)
  }

  const onDiscountChange = (discount: any) => {
    console.log(discount)
  }

  const defaultColumns: ColumnType[] = [
    {
      key: "sort",
      align: "center",
      width: 10,
      className: "drag-handler",
      render: () => <DragHandle disabled={lines.length < 2} />,
      dataIndex: "sort",
    },
    {
      title: "Détail de l'article",
      dataIndex: "description",
      align: "left",
      render: (_, record) => {
        if (record.title) {
          return <Input className='title-input' value={record.title} />
        }
        return <ArticleRow record={record} onSelect={onArticleSelect} />
      },
    },
    {
      title: "Quantité",
      dataIndex: "quantity",
      align: "right",
      render: (_, record) => {
        if (record.title) {
          return null
        }
        return <InputNumber min={0} precision={2} value={record.quantity} />
      },
    },
    {
      title: "Unité",
      dataIndex: "unit",
      align: "right",
      render: (t, record) => {
        if (record.title) {
          return null
        }

        return (
          <Select
            showSearch
            defaultValue={record.unit.suDisplayName}
            placeholder='U'
            optionFilterProp='label'
            onChange={onUnitChange}
            options={units}
            title={record.unit.suName}
          />
        )
      },
    },
    {
      title: "Prix Unitaire",
      dataIndex: "price",
      align: "right",
      render: (_, record) => {
        if (record.title) {
          return null
        }

        return (
          <InputNumber
            value={record.price}
            addonAfter={"€"}
            parser={(value) => value?.replace(/,/g, ".") as unknown as number}
            precision={2}
          />
        )
      },
    },
    {
      title: "Remise",
      dataIndex: "discount",
      align: "right",
      render: (_, record) => {
        if (record.title) {
          return null
        }
        return (
          <InputNumber
            value={record.price}
            addonAfter={
              <Select
                value={record.discount.type.type}
                defaultValue={DiscountType.PERCENT}
                optionFilterProp='label'
                onChange={onDiscountChange}
                options={Discount.getOptions()}
              />
            }
            parser={(value) => value?.replace(/,/g, ".") as unknown as number}
            precision={2}
          />
        )
      },
    },
    {
      title: "Taxe",
      dataIndex: "tax",
      align: "left",
      render: (_, record) => {
        if (record.title) {
          return null
        }
        return (
          <Select
            showSearch
            defaultValue={record.tax.taxeName}
            placeholder='TVA 5%'
            optionFilterProp='label'
            onChange={onTaxChange}
            options={taxes}
          />
        )
      },
    },
    {
      title: "Montant",
      dataIndex: "sum",
      align: "right",
      className: "sum",
      render: (_, record) => {
        if (record.title) {
          return null
        }
        // TODO add formatting
        const total = record.quantity * record.price
        const discount =
          record.discount.type.type === DiscountType.PERCENT
            ? total * (record.discount.value / 100)
            : record.discount.value
        return <Typography.Text>{Math.max(total - discount, 0)}€</Typography.Text>
      },
    },
    {
      dataIndex: "actions",
      className: "actions",
      render: (_, record) => (
        // TODO add cursor pointer
        <Flex justify='space-evenly'>
          {/* TODO make the dropdown larger to make it easier to click */}
          <Dropdown
            className='devis-row-actions'
            overlayClassName='devis-row-actions-overlay'
            menu={{
              items: [
                {
                  key: "1",
                  label: (
                    <>
                      <Typography.Text>Dupliquer cette ligne</Typography.Text>
                      <Divider dashed />
                    </>
                  ),
                  onClick: () => addLine(record),
                },
                {
                  key: "2",
                  label: "Ajouter une ligne",
                  // TODO make this add after the current line
                  onClick: () => addLine(),
                },
                {
                  key: "3",
                  // TODO handle title add
                  label: "Ajouter un titre",
                  onClick: () => addTitle("Nouveau titre"),
                },
              ],
            }}
            placement='bottomRight'
          >
            <MenuDotsVertical size={16} />
          </Dropdown>

          <X size={16} color='#ff4d4f' onClick={() => handleDelete(record.key)} />
        </Flex>
      ),
    },
  ]

  const columns = defaultColumns.map((col) => {
    if (!col.editable) {
      return col
    }
    return {
      ...col,
      onCell: (record: IRow) => ({
        record,
        editable: col.editable,
        dataIndex: col.dataIndex,
        title: col.title,
        handleSave,
      }),
    }
  })

  const handleSave = (row: IRow) => {
    const newData = [...lines]
    const index = newData.findIndex((item) => row.key === item.key)
    const item = newData[index]
    newData.splice(index, 1, {
      ...item,
      ...row,
    })
    setLines(newData)
  }

  return (
    <DndContext modifiers={[restrictToVerticalAxis]} onDragEnd={onDragEnd}>
      <SortableContext items={lines.map((i) => i.key)} strategy={verticalListSortingStrategy}>
        <Table
          scroll={{ x: 300 }}
          rowKey='key'
          components={{ body: { row: CustomRow } }}
          columns={columns as ColumnTypes}
          dataSource={lines}
          bordered
          pagination={false}
          size='small'
          rowClassName={(record) => (record.title ? "title-row" : "")}
        />
      </SortableContext>
    </DndContext>
  )
}

interface RowContextProps {
  setActivatorNodeRef?: (element: HTMLElement | null) => void
  listeners?: SyntheticListenerMap
}

const RowContext = React.createContext<RowContextProps>({})

const DragHandle: React.FC<{ disabled: boolean }> = ({ disabled }) => {
  const { setActivatorNodeRef, listeners } = useContext(RowContext)

  return disabled ? (
    <Button disabled={disabled} type='text' size='small' icon={<HolderOutlined />} />
  ) : (
    <Button
      disabled={disabled}
      type='text'
      size='small'
      icon={<HolderOutlined />}
      style={{ cursor: "move" }}
      ref={setActivatorNodeRef}
      {...listeners}
    />
  )
}

interface RowProps extends React.HTMLAttributes<HTMLTableRowElement> {
  "data-row-key": string
}

const CustomRow: React.FC<RowProps> = (props) => {
  const {
    attributes,
    listeners,
    setNodeRef,
    setActivatorNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({ id: props["data-row-key"] })

  const style: React.CSSProperties = {
    ...props.style,
    transform: CSS.Translate.toString(transform),
    transition,
    ...(isDragging ? { position: "relative", zIndex: 9999 } : {}),
  }

  const contextValue = useMemo<RowContextProps>(
    () => ({ setActivatorNodeRef, listeners }),
    [setActivatorNodeRef, listeners],
  )

  return (
    <RowContext.Provider value={contextValue}>
      <tr {...props} ref={setNodeRef} style={style} {...attributes} />
    </RowContext.Provider>
  )
}
