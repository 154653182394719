import { Form } from "antd"
import { useEffect, useState } from "react"
import { ContactAPI } from "../../../../../../services/Contacts.api"
import { ContactForm } from "../../../../../contact/components/ContactForm/ContactForm"
import NDFSelect from "../NDFSelect"
import "./contactSearch.scss"

interface Props {
  onSelect: (value: number) => void
  label: string
  value: number | undefined
}

interface IContactNameId {
  value: number
  label: string
}

export const ContactSearch: React.FC<Props> = ({ onSelect, value, label }) => {
  const [contactNameIds, setContactNameIds] = useState<IContactNameId[]>([])
  const [loading, setLoading] = useState(false)
  const [search, setSearch] = useState("")
  const [showModal, setShowModal] = useState(false)

  useEffect(() => {
    setLoading(true)

    const timeout = setTimeout(() => {
      searchContact(search)
    }, 500)

    return () => {
      clearTimeout(timeout)
      setLoading(false)
    }
  }, [search])

  const searchContact = (search: string) => {
    if (search.length && search.length < 3) {
      return
    }
    return ContactAPI.searchForContact(search).then((res) => {
      if (res.ok) {
        const contacts = res.data?.map((contact) => ({
          value: contact.IDTiers,
          label: contact.tiersDenomination,
        }))
        setContactNameIds(contacts)
      }
      setLoading(false)
    })
  }

  return (
    <>
      <Form.Item name='idContact' className='contact-search' label={label}>
        <NDFSelect
          search={search}
          options={contactNameIds}
          onSearch={setSearch}
          onSelect={onSelect}
          value={value}
          isLoading={loading}
          onNew={() => setShowModal(true)}
          placeholder={{ notFound: "Aucun contact trouvé", search: "Recherchez un contact" }}
        />
      </Form.Item>
      <ContactForm
        id={undefined}
        isOpen={showModal}
        onClose={() => setShowModal(false)}
        onSubmit={(contact) => {
          setShowModal(false)
          contact && onSelect && onSelect(contact.IDTiers)
          searchContact(`${contact?.prenom ?? contact?.denomination} ${contact?.nom}`)
        }}
      />
    </>
  )
}
